import { useRouter } from 'next/router';
import { useEffect } from 'react';

const MyPage = () => {
	const router = useRouter();

	useEffect(() => {
		router.push('/video');
	}, []);

	return <></>;
};

// export function getServerSideProps(context: any) {
// 	return {
// 		redirect: {
// 			permanent: false,
// 			destination: "/video",
// 		},
// 		props: {},
// 	};
// };

export default MyPage;
